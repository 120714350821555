<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">SSO configuration for {{event.name}}</v-card-title>

      <v-card-text>      
        <p>
          Enable and configure SSO for this event.
        </p>

        <v-alert v-if="event.sso_config" type="success">
          <p>This event has SSO enabled.</p>
        </v-alert>
      </v-card-text>

      <v-card-title>SSO Configuration</v-card-title>
      <v-card-text>
        Please contact support to help you configure this correctly.
      </v-card-text>
      <v-alert v-if="!eventUtil.isEnterprisePlan()" type="info" tile>
        <p><router-link class="white--text" :to="{name: 'eventmanagerLicense', params: {id:event.id}}">Upgrade to the Enterprise plan</router-link> to enable SSO.</p>
      </v-alert>
      <v-card-text>
        <v-text-field
          v-model="config.firebase_provider"
          label="Firebase Provider ID"
          />
        <v-switch 
          v-model="config.required"
          label="Require SSO login"
          persistent-hint
          hint="When enabled, users are forced to authenticate through SSO when they want to join this event."
          />
        <v-combobox
          v-if="config.required"
          v-model="config.valid_domains"
          label="Valid domains (optional)"
          persistent-hint
          hint="Enter one or more valid domain names, each followed by the TAB key. When used, only users with an email address from one of the valid domains may join."
          class="mt-4"
          multiple
          chips
          />
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="!eventUtil.isEnterprisePlan()" color="secondary" @click="save">{{$t('shared.save')}}</v-btn>
      </v-card-actions>

    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import EventUtil from "@/util/eventUtil";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "IntegrationsSso",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      siteData: siteData,
      tenant: tenant,
      event: null,
      config: {},
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async getEvent(id) {
      this.event = (await eventManagerService.get(id)).data;
      this.config = this.event.sso_config || {};
    },

    async loadData() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async save() {
      const response = (await eventManagerService.integrationsConfigureSso(this.event.id, this.config)).data;
      await this.loadData();
      this.$helpers.toastResponse(this, response, 'Successfully configured SSO.');
    },


  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Integrations', exact: true, to: { name: 'eventmanagerIntegrations', params: {id: this.event.id}} },
        { text: 'SSO', disabled: true },
      ];
    },
    eventUtil() {
      return new EventUtil(this, this.event);
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

